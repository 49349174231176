import React, { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { RegisterPupil } from "pages/RegisterPupil";
import { Schools } from "pages/schools/Schools";
import { SchoolForm } from "pages/schools/SchoolForm";
import { Classes } from "pages/classes/Classes";
import { ClassForm } from "pages/classes/ClassForm";
import { Code } from "pages/Code";
import { Pupils } from "pages/Pupils";
import { PupilsTransfer } from "pages/PupilsTransfer";
import { StatisticSchools } from "pages/statistic/StatisticSchools";
import { StatisticClasses } from "pages/statistic/StatisticClasses";
import { StatisticPupils } from "pages/statistic/StatisticPupils";
import { StatisticPupil } from "pages/statistic/StatisticPupil";
import { StatisticClassesFilters } from "pages/statistic/StatisticClassesFilters";
import { Meetings } from "pages/meetings/Meetings";
import { MeetingForm } from "pages/meetings/MeetingForm";
import { TestsPage } from "pages/TestsPage";
import { SolutionPage } from "pages/SolutionPage";
import { TestPage } from "pages/TestPage";
import { ProfilePage } from "pages/ProfilePage";
import { CreateTestPage } from "pages/CreateTestPage";
import { SettingsPage } from "pages/SettingsPage";
import { SecurityPage } from "pages/SecurityPage";
import { LandingPage } from "pages/LandingPage";
import { PupilPage } from "pages/pupils/PupilPage";
import { AdvicesPage } from "pages/AdvicesPage";
import { NotesPage } from "pages/NotesPage";
import { AlarmsPage } from "pages/AlarmsPage";
import { LosePassPage } from "pages/LosePassPage";
import { CategoriesPage } from "pages/CategoriesPage";
import { CreateTestFilters } from "pages/CreateTestFilters";
import { RegisterPsych } from "pages/RegisterPsych";
import { InstructionPage } from "pages/InstructionPage";
import { RiskGroups } from "pages/RiskGroups";
import { ParentContacts } from "pages/ParentContacts";
import { PupilAccesses } from "pages/PupilAccesses";
import { WorkWithMethods } from "pages/methods/WorkWithMethods";
import { WorkWithMethodsSlug } from "pages/methods/WorkWithMethodsSlug";
import { WorkWithMethodsById } from "pages/methods/WorkWithMethodsById";
import { SuggestionsPage } from "pages/SuggestionsPage";
import { ClassesAddExcelPage } from "pages/ClassesAddExcelPage";
import { ManagementReportingPage } from "pages/ManagementReportingPage";
import { Loader } from "components/Loader";
import { LoginPsychPage } from "pages/LoginPsychPage";
import { GuidelinesPage } from "pages/profile/GuidelinesPage";
import { PwaPincodePage } from "pages/login/PwaPincode";
import { AdvicesSchools } from "pages/advices/AdvicesSchools";
import { AdvicesClasses } from "pages/advices/AdvicesClasses";
import { AdvicesPupils } from "pages/advices/AdvicesPupils";
import { PupilsPage } from "pages/pupils/PupilsPage";
import { GuidelinesSlugPage } from "pages/profile/GuidelinesSlugPage";
import {AddPupils} from "../pages/AddPupils";

const LoginPupilPage = lazy(() => import("pages/LoginPupilPage"));
const PupilQrCode = lazy(() => import("pages/PupilQrCode"));

export function AppRouter() {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Route path="/" component={LandingPage} exact />
                    <Route path="/dashboard" component={ProfilePage} exact />
                    <Route path="/psych/register" component={RegisterPsych} />
                    <Route path="/psych/login" component={LoginPsychPage} />
                    <Route path="/pupil/login" component={LoginPupilPage} exact />
                    <Route path="/pupil/qr" component={PupilQrCode} />
                    <Route path="/pupil/login/:classId" component={LoginPupilPage} />
                    <Route path="/psych/lose" render={() => <LosePassPage role="psych" />} />
                    <Route path="/pupil/lose" render={() => <LosePassPage role="pupil" />} />
                    <Route path="/pupil/:classId" component={RegisterPupil} />
                    <Route path="/pincode" component={PwaPincodePage} />
                    <Route path="/advices/schools" component={AdvicesSchools} exact />
                    <Route path="/advices/schools/:id/classes" component={AdvicesClasses} />
                    <Route path="/advices/classes/:id/pupils" component={AdvicesPupils} />
                    <Route path="/advices" component={AdvicesPage} exact />
                    <Route path="/schools" component={Schools} exact />
                    <Route path="/schools/create" component={SchoolForm} exact />
                    <Route path="/schools/:id" component={SchoolForm} exact />
                    <Route path="/schools/:id/classes" component={Classes} exact />
                    <Route path="/schools/:schoolId/classes/create" component={ClassForm} />
                    <Route path="/classes/:id/add-pupils/code" component={Code} />
                    <Route path="/classes/:id/add-pupils/excel" component={ClassesAddExcelPage} />
                    <Route path="/classes/:id/add-pupils" component={AddPupils} />
                    <Route path="/classes/:id/pupils/transfer" component={PupilsTransfer} />
                    <Route path="/classes/:id/pupils" component={Pupils} />
                    <Route path="/classes/:id" component={ClassForm} exact />
                    <Route path="/statistic/schools" component={StatisticSchools} exact />
                    <Route path="/statistic/schools/:id" component={StatisticClasses} />
                    <Route path="/statistic/classes/:id" component={StatisticPupils} exact />
                    <Route path="/statistic/pupils/:id" component={StatisticPupil} exact />
                    <Route
                        path="/statistic/classes/:classId/filters"
                        component={StatisticClassesFilters}
                    />
                    <Route
                        path="/statistic/pupils/:pupilId/filters"
                        component={StatisticClassesFilters}
                    />
                    <Route path="/pupils" component={PupilsPage} exact />
                    <Route path="/pupils/:id" component={PupilPage} exact />
                    <Route path="/pupils/:id/categories" component={CategoriesPage} />
                    <Route path="/pupils/:id/parent-contacts" component={ParentContacts} />
                    <Route path="/pupils/:id/accesses" component={PupilAccesses} />
                    <Route path="/pupils/:pupilId/meetings" component={Meetings} exact />
                    <Route path="/pupils/:pupilId/meetings/create" component={MeetingForm} />
                    <Route path="/pupils/:pupilId/meetings/:meetingId" component={MeetingForm} />
                    <Route path="/riskGroups" component={RiskGroups} exact />
                    <Route path="/meetings" component={Meetings} exact />
                    <Route path="/meetings/schools" component={Schools} exact />
                    <Route path="/meetings/schools/:id/classes" component={Classes} exact />
                    <Route path="/meetings/classes/:id/pupils" component={Pupils} exact />
                    <Route path="/meetings/:meetingId" component={MeetingForm} />
                    <Route path="/notes" component={NotesPage} exact />
                    <Route path="/alarms" component={AlarmsPage} exact />
                    <Route path="/suggestions" component={SuggestionsPage} exact />
                    <Route path="/instruction" component={InstructionPage} exact />
                    <Route path="/guidelines" component={GuidelinesPage} exact />
                    <Route path="/guidelines/:slug" component={GuidelinesSlugPage} exact />
                    <Route path="/workWithMethods" component={WorkWithMethods} exact />
                    <Route path="/workWithMethods/methods/:id" component={WorkWithMethodsById} />
                    <Route path="/workWithMethods/:slug" component={WorkWithMethodsSlug} exact />
                    <Route path="/tests" component={TestsPage} exact />
                    <Route path="/tests/info/:id" component={TestsPage} exact />
                    <Route path="/tests/instruction/:id" component={TestsPage} exact />
                    <Route path="/tests/create" component={CreateTestPage} exact />
                    <Route path="/tests/create/:id" component={CreateTestPage} exact />
                    <Route path="/tests/create/:classId/filters" component={CreateTestFilters} />
                    <Route path="/tests/:testId/:conditionId" component={TestPage} />
                    <Route path="/solutions/:id" component={SolutionPage} />
                    <Route path="/settings" component={SettingsPage} exact />
                    <Route path="/settings/security" component={SecurityPage} exact />
                    <Route path="/management-reporting" component={ManagementReportingPage} exact />

                    <Redirect to="/" />
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
}
