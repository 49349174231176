import React, {useEffect, useState} from "react";
import {getSchools} from "../../redux/actions/setSchoolActions";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";

export const RuskGroupsFilter = ({changeFiltersList}) => {
    const dispatch = useDispatch();
    const { schools } = useSelector((state) => state.school);

    const [categories, setCategories] = useState([]);
    const [riskGroupsFilter, setRiskGroupsFilter] = useState([]);

    useEffect(() => {
        if (!schools?.length) {
            dispatch(getSchools());
        }
        (async () => {
            const { data } = await axios.get(`categories`);
            setCategories(data.categories);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const changeFilterHandler = (e) => {
        const id = e.target.value;
        let newFilters;

        if (riskGroupsFilter.includes(id)) {
            newFilters = riskGroupsFilter.filter((item) => item !== id);
        } else {
            newFilters = [...riskGroupsFilter];
            newFilters.push(id);
        }

        setRiskGroupsFilter(newFilters);
        changeFiltersList(newFilters)
    };



    return (
        <div
            className="risk-groups__filter"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {categories.map(({_id, title}) => {
                const isChecked = riskGroupsFilter.includes(_id);

                return (
                    <div className="risk-groups__filter-item" key={_id}>
                        <input
                            id={_id}
                            type="checkbox"
                            value={_id}
                            checked={isChecked}
                            onChange={changeFilterHandler}
                        />
                        <label htmlFor={_id}>{title}</label>
                    </div>
                );
            })}
        </div>
    )
}